import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  constructor(private http: HttpClient) {}

  getAvatarSkins() {
    return {
      clothes: [
        {
          value: 'maid-dress',
          url: 'https://d1a370nemizbjq.cloudfront.net/77b63ded-f5fa-4eb2-afb1-aac1a6330184.glb',
          icon: 'assets/creator/images/cloths.svg',
        },

        {
          value: 'suit',
          url: 'models/gltf/saber-suit/saber-suit.gltf',
          icon: 'assets/creator/images/cloths.svg',
        },
      ],
      hair: [
        {
          value: 'maid',
          url: 'models/gltf/saber-maid-hair-mixamo/saber-maid-hair.gltf',
          icon: 'assets/creator/images/hairs.svg',
        },
        {
          value: 'lily',
          url: 'models/gltf/saber-lily-hair-sub-skeleton/saber-lily-hair-sub-skeleton.gltf',
          icon: 'assets/creator/images/hairs.svg',
        },
      ],
      face: [
        {
          value: 'saber',
          url: 'models/gltf/saber-face/saber-face.gltf',
          icon: 'assets/creator/images/face.svg',
        },
        {
          value: 'eriri',
          url: 'models/gltf/saber-face/eriri-face-test.gltf',
          icon: 'assets/creator/images/face.svg',
        },
      ],
    };
  }
  getAvatars() {
    return of([
      {
        poster: 'assets/models/icons/1.png',
        url: 'assets/models/glb/1.glb',
      },
      {
        poster: 'assets/models/icons/2.png',
        url: 'assets/models/glb/2.glb',
      },
      {
        poster: 'assets/models/icons/3.png',
        url: 'assets/models/glb/3.glb',
      },
      {
        poster: 'assets/models/icons/4.png',
        url: 'assets/models/glb/4.glb',
      }
      // {
      //   poster: 'assets/models/icons/5.png',
      //   url: 'assets/models/glb/5.glb',
      // },
    ]);
  }

  updateMyAvatar(user: any) {
    return this.http.post(`${environment.SERVER_URL}/avatar`, user);
  }
}
