<div class="form-page-container">
    <app-steps></app-steps>
  <div class="step-page-container">
    <div class="form-step">
        <ng-container [ngSwitch]="currentStep?.stepIndex">
            <ng-container *ngSwitchCase="'1'">
              <select-body-type [step]="currentStep" (onNextStep)="onNextStep()" ></select-body-type>
                <!-- <app-step-template [step]="currentStep" ></app-step-template> -->
            </ng-container>
            <ng-container *ngSwitchCase="'2'">
              <app-model-selector [step]="currentStep" (saveModeEvent)="onModelSave($event)"></app-model-selector>
            </ng-container>
            <!-- <ng-container *ngSwitchCase="'3'">
              <app-step-template [step]="currentStep"></app-step-template>
            </ng-container> -->
        </ng-container>
    </div>
  </div>
  <!-- <button (click)="onNextStep()" [disabled]="!currentStep?.isComplete" class="form-continue-btn common-btn">
    {{ showButtonLabel() }}
  </button> -->
</div>
