import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StepModel } from 'src/app/models/step.model';


@Component({
  selector: 'select-body-type',
  templateUrl: './select-body-type.component.html',
  styleUrls: ['./select-body-type.component.scss']
})
export class SelectBodyTypeComponent implements OnInit {

  constructor(private router:Router) { }
  @Input() step: StepModel;
  ngOnInit(): void {
  }
  @Output() onNextStep = new EventEmitter<boolean>();
  
  nextStep() {
    this.step.isComplete = true;
    this.onNextStep.emit(true);
    // this.router.navigate(['/complete']);

  }
  // onCompleteStep() {
  
  // }

}
