<div class="step-template text-center">
  <!-- <h2>Step {{ step.stepIndex }}</h2> -->
  <a class="btn btn-primary pull-right" (click)="saveModel()">Finish</a>

  <div class="grid-container">
    <!-- <div id="container" style="background-color: white;"></div> -->
    <model-viewer class="a"
    alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
    src="assets/models/glb/1.glb"
    arar-modes="webxr scene-viewer quick-look"
    shadow-intensity="1"
    camera-controls
                  ></model-viewer>
  </div>
  <!-- <nav class="nav_top" *ngIf="selectedAvatarSkin.length">
      <a *ngFor="let skin of selectedAvatarSkin" (click)="changeSkin(skin.value)" class="nav__link">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" [src]="skin.icon">
        <i class="material-icons nav__icon">dashboard</i>
        <span class="nav__text">{{skin.value}}</span>
      </a>
     
    </nav> -->
  <nav class="nav">
    <a
      *ngFor="let avatar of avatars"
      (click)="changeModel(avatar)"
      class="nav__link"
    >
      <img
        class="h-10 w-10 z-0 CustomizationIcons_customizationIconActive__1zFA8"
        [src]="avatar.poster"
      />
    </a>
    <!-- <a (click)="change('clothes','suit')" class="nav__link nav__link--active">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" src="assets/creator/images/avatar1.png">
      </a>
      <a (click)="change('clothes','suit')" class="nav__link">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" src="assets/creator/images/avatar1.png">

      
      </a> -->

    <!-- <a (click)="change('hair','lily')" class="nav__link">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" src="assets/creator/images/hairs.svg">

       
      </a>
      <a href="#" class="nav__link">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" src="assets/creator/images/eyes.svg">

       
      </a>
      <a href="#" class="nav__link">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" src="assets/creator/images/beard.svg">

      
      </a>
      <a href="#" class="nav__link">
          <img class="h-10 w-10 z-0  CustomizationIcons_customizationIconActive__1zFA8" src="assets/creator/images/glass.svg">

       
      </a> -->
  </nav>
</div>
