import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { AvatarService } from 'src/app/services/avatar.service';
import { StepModel } from '../../models/step.model';

declare const viewer: any;

@Component({
  selector: 'app-step-template',
  templateUrl: './step-template.component.html',
  styleUrls: ['./step-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepTemplateComponent implements OnInit, AfterViewInit {
  @Input() step: StepModel;
  types = {
    hair: 'maid',
    clothes: 'suit',
    face: 'saber',
  };
  avatarSkins = {};
  selectedAvatarSkin=[];
  selectedType = 'face';
  constructor(private avatarService: AvatarService) {}

  ngOnInit(): void {
   this.avatarSkins= this.avatarService.getAvatarSkins();
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }
  ngAfterViewInit(): void {
    this.loadScript('assets/creator/app.js');
  }
  public loadScript(url: string) {
    console.log('preparing to load...');
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  change(type: string, value: string) {
    // viewer.selectSkin(type, value);
    this.selectedType=type;
    this.selectedAvatarSkin= this.avatarSkins[this.selectedType];
    console.log(this.selectedAvatarSkin);
  }

  changeSkin(value){
     viewer.selectSkin(this.selectedType, value);
  }
}
