import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import '@google/model-viewer';
import { GLTFLoader } from 'src/assets/creator/GLTFLoader.js';
import { StepModel } from 'src/app/models/step.model';
import { AvatarService } from 'src/app/services/avatar.service';
@Component({
  selector: 'app-model-selector',
  templateUrl: './model-selector.component.html',
  styleUrls: ['./model-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModelSelectorComponent implements OnInit, AfterViewInit {
  @Input() step: StepModel;
  @Output() saveModeEvent = new EventEmitter<any>();
  types = {
    hair: 'maid',
    clothes: 'suit',
    face: 'saber',
  };
  avatarSkins = {};
  avatars: any[] = [];
  selectedAvatarSkin = [];
  selectedModel: string = '';
  selectedType = 'face';
  constructor(
    private avatarService: AvatarService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.avatarService.getAvatars().subscribe((data) => {
      this.avatars = data;
      // this.changeModel(this.avatars[0]);
    });
    this.avatarSkins = this.avatarService.getAvatarSkins();
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }
  ngAfterViewInit(): void {
   
    this.loadScript('assets/creator/app.js');
  }
  public loadScript(url: string) {
    console.log('preparing to load...');
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  saveModel() {
    this.saveModeEvent.next(this.selectedModel);
  }
  changeModel(model) {
    const modelViewer = <any>document.querySelector('model-viewer');

    modelViewer.src = model.url;
    modelViewer.poster = model.poster;
    this.selectedModel = model;
    // setTimeout(() => {
    //   modelViewer.setAttribute(model.poster);
    // }, 1000);

    this.cdr.detectChanges();
  }
  change(type: string, value: string) {
    const modelViewer = <any>document.querySelector('model-viewer');

    const base = '../../assets/ShopifyModels/' + name;
    modelViewer.src = base + '.glb';
    modelViewer.poster = base + '.png';
    // viewer.selectSkin(type, value);
    // this.selectedType = type;
    // this.selectedAvatarSkin = this.avatarSkins[this.selectedType];
    // console.log(this.selectedAvatarSkin);
  }

  // changeSkin(value){
  //    viewer.selectSkin(this.selectedType, value);
  // }
}
