import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StepModel } from '../../models/step.model';
import { Subscription } from 'rxjs';
import { StepsService } from '../../services/steps.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AvatarService } from 'src/app/services/avatar.service';

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormPageComponent implements OnInit {
  currentStep: StepModel;
  currentStepSub: Subscription;

  constructor(
    private stepsService: StepsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private avatarService: AvatarService
  ) {}
  userId: string;
  selectedAvatarUrl: string;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userId = params['userId'];
    });
    this.currentStepSub = this.stepsService
      .getCurrentStep()
      .subscribe((step: StepModel) => {
        this.currentStep = step;
      });
  }
  onNextStep() {
    if (!this.stepsService.isLastStep()) {
      this.stepsService.moveToNextStep();
    } else {
      this.onSubmit();
    }
  }

  showButtonLabel() {
    return !this.stepsService.isLastStep() ? 'Continue' : 'Finish';
  }
  onModelSave(model: any) {
    this.selectedAvatarUrl = model.url;
    this.onSubmit();
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks and unexpected angular errors
    this.currentStepSub.unsubscribe();
  }

  onSubmit(): void {
    let userAvatar = {
      userName: this.userId,
      avatarUrl: this.selectedAvatarUrl,
    };
    this.avatarService.updateMyAvatar(userAvatar).subscribe((userData) => {
      this.router.navigate(['/complete']);
    });
  }
}
