<div class="photo-result table-cell align-middle py-4 text-center">
  <h1 class="mb-8 text-2xl sm:text-4xl pb-20" style="color: black; margin-bottom: 60;">Choose your body type</h1>

  <div class="row">
    <div class="text-center mt-10">
      <button
        type="button"
        (click)="nextStep()"
        id="female-btn"
        data-cy="female-button"
        class="btn btn-primary btn_body_type"
      >
        Masculine
      </button>
    </div>
  </div>
  <div class="row">
    <div class="text-center mt-10">
      <button
        type="button"
        (click)="nextStep()"
        id="female-btn"
        data-cy="female-button"
        class="btn btn-primary btn_body_type"
      >
        Feminine
      </button>
    </div>
  </div>
  <div class="row">
    <div class="text-center mt-10">
      <button  (click)="nextStep()"
        type="button"
        id="female-btn"
        data-cy="female-button"
        class="btn btn-primary btn_body_type"
      >
        Don't specify
      </button>
    </div>
  </div>
</div>
